/*
 * Site Fonts
 */

const fonts = {
  title: `'Josefin Sans', sans-serif`,
  subTitle: `'Advent Pro', sans-serif`,
  body: `'Advent Pro', sans-serif`,
}

export default fonts;
